import React from "react"
import { graphql } from "gatsby"
import tw from "twin.macro"
import Layout from "../components/Layout"
import Seo from "../components/Seo"

const AlabamaStateLaw = ({ data }) => {
  return (
    <Layout>
      <Seo title="Alabama State Law" />
      <div css={tw`container mx-auto py-16 md:py-32 px-4`}>
        <div
          css={tw`mx-auto prose lg:prose-xl`}
          dangerouslySetInnerHTML={{ __html: data.markdownRemark.html }}
        />
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    markdownRemark(frontmatter: { slug: { eq: "state-law" } }) {
      html
    }
  }
`

export default AlabamaStateLaw
